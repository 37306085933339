<template>
    <div class="donat">
        <div class="content">
            <div class="с-donat-desktop">
                <div class="c-d-textblock">
                    <h1 class="c-d-t-Title">Донат</h1>
                    <p class="c-info-text">
                        Мы сделали несколько типов привилегий, которые можно получить за донат. Конечно, вы можете
                        обойтись и обычным статусом игрока, но
                        эти привилегии добавят вам несколько новых команд, которые помогут вам на начальном этапе игры
                        или, например, могут сделать вас бессмертным. Привилегии выдаются сроком на 30 дней.
                    </p>
                </div>

                <transition name="fade" mode="out-in">

                    <div class="preloader" v-if="!server_names.length > 0"/>

                    <div class="c-buy-block" v-else>
                        <div class="d">
                            <table class="d-t">
                                <colgroup>
                                    <col/>
                                    <col class="bg_swith_color "/>
                                    <col class="">
                                    <col class="bg_swith_color"/>
                                    <col class="">
                                    <col class="bg_swith_color"/>
                                    <col class="">
                                </colgroup>
                                <thead>
                                <tr>
                                    <th class="type-title">
                                    </th>
                                    <th class="type-title"
                                        v-for="status in servers[ServerName].statuses">
                                        <br>
                                        {{ status.name }}
                                        <br>
                                    </th>
                                </tr>
                                </thead>

                                <tbody>
                                <tr>
                                    <th class="tth-plunk"></th>
                                    <th v-for="status in servers[ServerName].statuses"
                                        class="type-cost">
                                        ₽{{ status.price }}
                                    </th>
                                </tr>

                                <tr>
                                    <th class="select-th">
                                        <CustomSelect
                                            @send="ServerName = $event"
                                            :options="server_names"
                                            :default="'HiTech'"
                                            class="select"/>
                                    </th>
                                    <th v-for="status in servers[ServerName].statuses">
                                        <div class="c-buy">
                                            <div class="buy-btn" v-if="status.price > 0"
                                                 @click="buy_status(ServerName, status.id, status.name)">Купить
                                            </div>
                                            <div class="buy-btn price0" v-else>Бесплатно</div>
                                        </div>
                                    </th>
                                </tr>

                                <tr v-for="descript in servers[ServerName].keys">
                                    <th>
                                        <p class="с-border l-align">
                                            {{ descript.description }}
                                        </p>
                                    </th>
                                    <th v-for="infos in servers[ServerName].infos[descript.id]">
                                        <p class="с-border">
                                            {{ infos === null ? "-" : infos }}
                                        </p>
                                    </th>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </transition>
            </div>

        </div>
    </div>
</template>

<script>
import CustomSelect from "../components/elements/donat/CustomSelect";
import StatusBuyModal from "../components/modals/StatusBuyModal";

import {PreloaderMixin} from "../mixins/PreloaderMixin";


export default {
    name: "Donat",
    mixins: [PreloaderMixin],
    components: {
        CustomSelect,
    },

    metaInfo: {
        title: 'GreatRay',
        titleTemplate: '%s: Донат услуги',
    },

    data() {
        return {
            ServerName: "HiTech",
            servers: {},
            server_names: [],
        }
    },

    methods: {
        buy_status(serverName, status_id, statusName) {
            let player_data
            let authorize = true
            let rebuy = false
            if (localStorage.getItem('player')) {
                try {
                    player_data = JSON.parse(localStorage.getItem('player'))
                } catch (e) {
                    console.log("Не авторизованы?")
                }
            }
            try {
                player_data.luckperms[serverName].statuses.forEach(i => {
                    if (i.status.name === statusName) {
                        rebuy = true
                    }
                });
            } catch (e) {
                authorize = false
            }

            this.$modal.show(
                StatusBuyModal,
                {
                    "statusName": statusName,
                    "status_id": status_id,
                    "serverName": serverName,
                    "rebuy": rebuy,
                    "is_authorize": authorize,
                    "in_lk": false,
                },
                {
                    styles: "background: transparent; padding: 0; margin: 0; border-radius: 5px; box-shadow: none; ",
                    width: "420px",
                    height: "auto",
                    reset: true,
                }
            )
        }
    },

    created() {

        require('axios').get(`/api/server?donate=true`).then(
            data => {
                this.servers = {}
                data.data.forEach(i => {
                    this.servers[i.name] = i
                    this.server_names.push(i.name)
                })
                this.preloaderStop('.preloader')
            }
        );
        if (localStorage.getItem('player')) {
            this.$root.update_PlayerData();
        } else {
            console.log('Вы не авторизованы.')
        }

    },
    mounted() {
        this.preloaderStartUnblessed('.preloader')
    }
}

</script>
<style scoped lang="scss">

@import "src/assets/scss/mixins";
@import "src/assets/scss/vars";
@import "src/assets/scss/animations";

@include ct-n-rp-transitions();

.content {
    @include flexible-column();
    width: 100vw;
    min-height: 600px;
}

.с-donat-desktop {
    min-width: 65vw;
    max-width: 100%;
    min-height: 600px;
    height: auto;
    padding-bottom: 10vh;

    .c-d-textblock {
        @include flex-column();
        padding: 0 27px;
        margin-top: 100px;
        width: 65vw;
        min-width: 1000px;

        .c-d-t-Title {
            font-family: $main-font;
            @include text-normalize();
            color: $not-bright-white;
        }


        .c-info-text {
            @include info-text();
        }

    }

    @include in-page-preloader();

    .c-buy-block {
        padding: 10px 40px;
        width: 65vw;
        min-width: 1000px;
        border-radius: 20px;
        background-color: #1c1c1d;

        .d {
            padding-top: 51px;

            .d-t {
                flex-direction: column;
                border-spacing: 0;
                width: 100%;

                th {
                    padding: 0 10px;

                    p {
                        @include desctiption-0-8();
                        @include text-normalize();
                        line-height: 13px;
                        text-align: center;
                        color: $dull-gray;
                    }

                    .c-buy {
                        @include flexible();
                        margin-bottom: 15px;

                        .buy-btn {
                            @include main-button(5px, 30px);

                            &:hover {
                                transition: all 0.5s ease-in-out;
                                transform: scale(1.1);
                                color: $not-bright-white;
                            }
                        }

                        .price0 {
                            background: transparent;
                            cursor: default;
                        }

                    }

                    .l-align {
                        text-align: left;
                    }
                }

                @mixin th-ty-c() {
                    @include title-1-1();
                    @include text-normalize();
                    color: $not-bright-white;
                    text-align: center;
                }

                .type-title {
                    width: 220px;
                    height: 15px;
                    @include th-ty-c();
                }

                .type-cost {
                    width: 39px;
                    height: 50px;
                    @include th-ty-c();
                }

                .select-th {
                    @include flexible();
                    margin-bottom: 15px;

                    .select {
                        font-weight: 100;
                    }
                }

                .tth-plunk {
                    display: flex;
                    margin-left: 27%;
                }

                .bg_swith_color {
                    background: #222222;
                    width: 175px;
                }
            }
        }
    }
}

</style>
